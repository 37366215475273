const skeletonLoaderFaderClassName = "skeleton-fader";

export function skeletonLoadInit(querySelector)
{
    const elements = document.querySelectorAll(querySelector);

    for (let item of elements) {
        let skeleton = document.createElement("div");
        skeleton.className = skeletonLoaderFaderClassName;

        item.appendChild(skeleton);
    }
}

export function skeletonLoadRemove(querySelector)
{
    const elements = document.querySelectorAll(querySelector);

    const className = querySelector.substring(1);
    for (let item of elements) {
        item.classList.remove(className);
    }

    const faders = document.querySelectorAll("." + skeletonLoaderFaderClassName);
    for (let item of faders) {
        item.parentNode.removeChild(item);
    }  
}