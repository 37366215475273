export default function onscroll(event)
{
    handleScrollTop();
}

export function onloadScrolls()
{
    handleScrollTop();
}

function handleScrollTop()
{
    let scrollY = window.scrollY;
    let height = window.screen.height;

    const scrollTop = document.querySelector("#scroll-top");
    if (scrollY / height < 0.55) {
        deactivateScrollTop(scrollTop);
        return;
    }

    activateScrollTop(scrollTop)
}

function deactivateScrollTop(scrollTop)
{
    if (!scrollTop.classList.contains("visible")) {
        return;
    }

    scrollTop.classList.remove("visible");
    setTimeout(function () {
        scrollTop.classList.remove("active");
    }, 100)
}

function activateScrollTop(scrollTop)
{
    if (scrollTop.classList.contains("active")) {
        return;
    }

    scrollTop.classList.add("active");
    setTimeout(function () {
        scrollTop.classList.add("visible");
    }, 100)
}