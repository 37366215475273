const carouselParentSelector = "#feedback .own-carousel__container .own-carousel";

export default function initFeedback(config)
{
    if (config === undefined) {
        return;
    }

    loadContent(config["content"]);
    ownCarouselInit();
}

function loadContent(content)
{
    if (content === undefined) {
        return;
    }

    const parent = document.querySelector(carouselParentSelector);
    for (let item of content) {
        let card = document.createElement("div");
        card.className = "own-carousel__item";

        card.innerHTML = template(
            item["image"],
            item["author"],
            item["position"],
            item["rate"],
            item["text"]
        );

        parent.appendChild(card);
    }
    
    function template (imgSrc, name, position, rate, text) {
        var rating = "";
        let stars = 5;
        while (stars > 0) {
            let empty = rate <= 0 ? " empty" : "";
            rating += `
                <span class="star${empty}"></span>
            `;

            rate--;
            stars--;
        }

        // <img src="${imgSrc}">
        return `
        <div class="card">
            <div class="user-info">
                <div class="avatar">
                </div>
                <div class="about">
                    <div class="name">
                        <span class="primary">${name}</span>
                    </div>
                    <div class="position">
                        <span class="footnote">${position}</span>
                    </div>
                </div>
            </div>

            <div class="rating">${rating}</div>

            <div class="feedback">
                <p class="footnote quoted">${text}</p>
            </div>
        </div>
        `;
    }
}

function ownCarouselInit()
{
    document.querySelector(".own-carousel__container").ownCarousel({
        itemPerRow: 2, 
        itemWidth: 48, // percents
        loop: true,
        autoplay: 0, // false
        nav: true, // activate nav buttons
        responsive: {
            1023: [1, 100]
        }
    });
    responsive();
}
