export default function initMailService(config)
{
    const forms = document.querySelectorAll("form.callback");

    for (let form of forms) {
        form.addEventListener("submit", onSubmitProvider(form, config))
    }
    
}

function onSubmitProvider(form, config) {
    const fireLoad = document.querySelector("#fire button[fire=load]");
    const fireSuccess = document.querySelector("#fire button[fire=order-success]");
    const fireFail = document.querySelector("#fire button[fire=order-fail]");

    return function (event) {
        event.preventDefault();

        const data = new FormData(form);
        var url = config["send"];
        var bearer = "Bearer " + config["bearer"];
        var body = config["body"];

        body["data"]["template"]["orgName"] = data.get("org-name");
        body["data"]["template"]["region"] = data.get("region");
        body["data"]["template"]["phone"] = data.get("phone");
        body["data"]["template"]["email"] = data.get("email");

        fireLoad.click();
        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(resp => resp.json())
            .catch(err => {
                fireFail.click();
            })
            .then(answ => {
                if (answ.ok) {
                    fireSuccess.click();

                    setTimeout(function () {
                        document.location.reload();
                    }, 3000)
                } else {
                    fireFail.click();
                }
            });

        return false;
    }
}
