var onClose = false;
var idCounter = 1;

export default function initDataActions()
{
    templates();
    actions();
}

function templates()
{
    const templatesParents = document.querySelectorAll("[data-html]");
    if (templatesParents === undefined) {
        return;
    }

    for (let parent of templatesParents) {
        let tmpId = parent.getAttribute("data-html");
        if (tmpId.length == 0) {
            continue;
        }

        let template = document.querySelector("template#" + tmpId);
        if (template === undefined) {
            continue;
        }

        let content = template.content;
        parent.appendChild(document.importNode(content, true));

        const inputs = parent.querySelectorAll("input");
        if (inputs) {
            for (let input of inputs) {
                let id = input.id;
                let label = parent.querySelector(`label[for=${id}]`);
                if (label) {
                    id = id + "-" + idCounter;

                    input.id = id;
                    label.setAttribute("for", id)

                    idCounter++;
                }
            }
        }

        parent.removeAttribute("data-html");
    }
}

function actions()
{
    const actionNodes = document.querySelectorAll("[data-action]");
    if (actionNodes === undefined) {
        return;
    }

    for (let node of actionNodes) {
        let action = node.getAttribute("data-action");

        switch (action) {
            case "overflow":
                actionOverflow(node);
                break;
        }
    }
}

function actionOverflow(node)
{
    const overflow = document.querySelector("#overflow");
    if (overflow === undefined) {
        return;
    }

    const close = overflow.querySelector(".content .close");
    if (close === undefined) {
        return;
    }

    let targetId = node.getAttribute("data-target");
    if (targetId.length == 0) {
        return;
    }

    const targetNode = overflow.querySelector(`section[data-id=${targetId}]`);
    if (targetNode === undefined) {
        return;
    }

    node.addEventListener("click", function (event) {
        const sections = overflow.querySelectorAll("section.active");
        if (sections) {
            for (let section of sections) {
                section.classList.remove("active");
            }
        }

        overflow.classList.add("active");
        overflow.setAttribute("data-id", targetId);
        document.querySelector("html").classList.add("overflow");

        setTimeout(function () {
            overflow.classList.add("visible");
            targetNode.classList.add("active");
        }, 100);
    })

    if (onClose) {
        return;
    }

    close.addEventListener("click", function (event) {
        overflow.classList.remove("visible");
        document.querySelector("html").classList.remove("overflow");

        setTimeout(function () {
            overflow.classList.remove("active");
            targetNode.classList.remove("active");
        }, 500);
    });
}
