const priceSelector = "#prices .banner.price span.price";

export default function initPrices(config)
{
    if (config === undefined) {
        return;
    }

    initPerYearPrice(config["year_price"]);
}

function initPerYearPrice(price) {
    const preparedPrice = priceWhitespaces(price);
    if (preparedPrice === false) {
        return;
    }

    const span = document.querySelector(priceSelector);
    span.textContent = preparedPrice;
}

function priceWhitespaces(price)
{
    if (price === undefined) {
        return false;
    }

    let source = "" + price;

    let pricePieces = [];
    while (source.length > 3) {
        let lastDigits = source.substring(source.length - 3);
        source = source.slice(0, -3);

        pricePieces.unshift(lastDigits);
    }

    pricePieces.unshift(source);
    return pricePieces.join(" ");
}
