import initDataActions from "../components/dataActions";
import initMailService from "../components/mailService";
import { skeletonLoadInit, skeletonLoadRemove } from "../components/skeletonLoad";
import initYMap from "../components/yMap";
import initFeedback from "../sections/feedback";
import initPrices from "../sections/prices";

const skeletonLoadSelector = ".skeleton-load";

export function onload(configPromise)
{
    initDataActions();

    skeletonLoadInit(skeletonLoadSelector);
    configPromise.then(config => {
        initPrices(config["prices"]);
        initFeedback(config["feedback"]);
        initMailService(config["mail-service"]);

        skeletonLoadRemove(skeletonLoadSelector);
    });

    initYMap();
    AOS.init();
}
