const CENTER = [56.837854, 60.665073];
const yMapId = "yandex-map";

export default function initYMap() {
    if (window.ymaps === undefined) {
        return;
    }
    ymaps.ready(ready);
}

function ready() {
    const ymapInstance = new ymaps.Map(
        yMapId,
        {
            center: CENTER,
            zoom: 16,
            controls: ["zoomControl"]
        },
        {
            autoFitToViewport: "always"
        }
    );

    ymapInstance
        .geoObjects
        .add(
            new ymaps.Placemark(
                CENTER,
                {
                    balloonContent: "Проект «Сайтымедицине.рф»"
                },
                {
                    preset: "islands#icon"
                    // iconColor: '#0095b6'
                }
            )
        );
}