import { onload } from "./window_events/onload";
import onscroll, { onloadScrolls } from "./window_events/onscroll";

window.addEventListener("load", function () {
    const configJSONPromise =
        fetch("/local/config.json")
            .then(resp => resp.json());

    onload(configJSONPromise);
    onloadScrolls();
});

window.addEventListener("scroll", onscroll);